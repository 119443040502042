var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-data-placeholder"
  }, [_c('div', {
    staticClass: "gn-data-placeholder__icon"
  }, [_c('InformationCircleIcon')], 1), _c('h2', {
    staticClass: "gn-data-placeholder__header"
  }, [_vm._v(_vm._s(_vm.header))]), _c('p', {
    staticClass: "gn-data-placeholder__desc"
  }, [_vm._v(_vm._s(_vm.description))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }