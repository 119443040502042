




































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters, mapState } from 'vuex';
import InsightFiltersNew from '../components/InsightFiltersNew.vue'
import PostBox from '../components/PostBox.vue';
import { InsightItem } from '../store/types';
import { BPagination } from 'bootstrap-vue';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import { Watch } from 'vue-property-decorator';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@/assets/icons/heroicons/heroicons';
import GenproxField from '@/components/inputs/GenproxField.vue';
import GenproxDataPlaceholder from '@/components/layout/GenproxDataPlaceholder.vue';
import { TableData } from '../../profile/store/types';

@Component({
  components: { InsightFiltersNew, PostBox, BPagination, SygniLoader, GenproxPageHeading, SygniInput, ChevronLeftIcon, ChevronRightIcon, GenproxField, GenproxDataPlaceholder },
  computed: {
    ...mapState('insights', {
      tableData: (state: any) => state.insightsTable
    }),
    ...mapGetters('insights', {
      insights: 'getInsights',
    })
  }
})
export default class InsightsModule extends Vue {
  tableData!: TableData<InsightItem>;
  insights!: Array<InsightItem>;
  currentPage: number = 1;
  isLoading: boolean = false;
  localQuery: TableQuery;
  hasBorder: boolean = false;

  scrollTop: number = 0;

  onFiltersChange(filtersQuery?: string): void {
    this.isLoading = true;
    this.$store.commit('insights/setInsightsTableFiltersQuery', filtersQuery);
    this.currentPage = 1;
    this.$nextTick(() => {
      this.getInsights();
    });
  }

  get filtersQuery() {
    return this.$store.getters['insights/getInsightsTableFiltersQuery'];
  }

  get perPage() {
    return this.$store.getters['insights/getInsightsTablePerPage'];
  }

  get totalCount() {
    return this.$store.getters['insights/getInsightTableTotalCount'];
  }

  setOffsetQuery() {
    this.isLoading = true;
    const offset = this.perPage * (this.currentPage - 1);
    this.$store.commit('insights/setInsightTableOffsetQuery', offset);
    this.getInsights();
  }

  async getInsights(clear: boolean = false) {
    if (clear) {
      // this.$store.commit('insights/setInsightsTableFiltersQuery', '');
      this.currentPage = Number(this.$route?.query?.page) || 1;
      // this.setOffsetQuery();
    }

    await this.$store.dispatch('insights/getInsights', this.filtersQuery);
    const appContentDiv: HTMLDivElement = document.querySelector('.app-content');
    appContentDiv?.scrollTo({top: 0, behavior: 'smooth'});
    this.isLoading = false;
  }

  @Watch('currentPage') onCurrentPageChange() {
    this.setOffsetQuery();
  }
  
  async beforeMount() {
    this.isLoading = true;
    await this.$store.dispatch('profile/getDictionaries');
    await this.getInsights(true);
    // this.handleScrolls()
  }

  // mounted() {
  //   this.$root?.$el?.querySelector('.app-content').addEventListener('scroll', this.handleScrolls)
  // }
  
  // beforeDestroy() {
  //   this.$root?.$el?.querySelector('.app-content').removeEventListener('scroll', this.handleScrolls)
  // }

  // handleScrolls(): void {
  //   const offsetHeight = ((this.$refs?.gnPageHeading as any)?.$el as HTMLDivElement)?.offsetHeight + (this.$refs?.filters as InsightFiltersNew).getFiltersOffset();
  //   this.hasBorder = (this.$root?.$el?.querySelector('.app-content')?.scrollTop >= offsetHeight) ? true : false
  // }
}
