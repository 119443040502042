var render = function () {
  var _vm$selectedFilters;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: "insight-filters-".concat(_vm.reRender),
    class: ['insight-filters', (_vm$selectedFilters = _vm.selectedFilters) !== null && _vm$selectedFilters !== void 0 && _vm$selectedFilters.length ? 'sticky' : '', _vm.borderClass]
  }, [_c('div', {
    ref: "filtersContainer",
    staticClass: "insight-filters__container"
  }, [_c('div', {
    staticClass: "insight-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['title']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    staticClass: "insight-filters__category",
    attrs: {
      "componentId": "categoryField",
      "label": "Category",
      "placement": "bottom",
      "type": "select",
      "options": _vm.focusAreas
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.tags.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.tags, "value", $$v);
      },
      expression: "filters.tags.value"
    }
  })], 1), _c('div', {
    staticClass: "insight-filters__clear-wrapper"
  }, [_c('a', {
    class: ['insight-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }