












import Vue from 'vue'
import Component from 'vue-class-component'
import { XMarkIcon } from '@/assets/icons/heroicons/heroicons';
import { Prop } from 'vue-property-decorator';

// TODO: Make some description about how does it works.
// Currency format cant be used with number input
// Refactor this someday please
@Component({
  components: { XMarkIcon }
})
export default class GenproxFilter extends Vue {
  @Prop({ default: null }) filter: { filterName: string, label: any, value: any }
}
