










import Vue from 'vue'
import Component from 'vue-class-component'
import { InformationCircleIcon } from '@/assets/icons/heroicons/heroicons';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';

@Component({
  components: { InformationCircleIcon }
})
export default class GenproxDataPlaceholder extends Vue {
  @Prop({ default: null }) header!: string
  @Prop({ default: null }) description!: string
}
