


























import Component, { mixins } from 'vue-class-component'
import {Filter, FilterFunctions, FilterMixin, FilterOperators} from "@/shared/mixins/FilterMixin";
import {mapGetters } from "vuex";
import { ResizeObserver } from 'vue-resize'
import GenproxField from '@/components/inputs/GenproxField.vue';
import { ChevronDownIcon, MagnyfyingGlassIcon } from '@/assets/icons/heroicons/heroicons';
import GenproxFilter from '@/components/inputs/GenproxFilter.vue';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { Prop } from 'vue-property-decorator';


@Component({
  components: { GenproxField, GenproxFilter, simplebar, ResizeObserver },
  computed: {
    ...mapGetters('profile', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class InsightFiltersNew extends mixins (FilterMixin) {
  @Prop({ default: false }) hasBorder: boolean;

  MagnyfyingGlassIcon: any = MagnyfyingGlassIcon;
  ChevronDownIcon: any = ChevronDownIcon;
  // filtersOffset: number = 0
  reRender: number = 0
  
  itemsUrl: string = 'insight';
  
  orFilters: Record<string, Filter> = {
    title: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string', label: 'Search' },
  }

  filters: Record<string, Filter> = {
    tags: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.contain, type: 'string', label: 'Category' },
  };

  // getFiltersOffset() {
  //   return this.filtersOffset
  // }

  handleSearch(value: any, orFilters: string[]) {
    if (orFilters?.length) {
      orFilters?.forEach((filterName: string) => {
        this.$set(this.orFilters[filterName], 'value', value || null)
      })
    }

    this.applyFilters()
  }

  get borderClass() {
    return this.selectedFilters?.length && this.hasBorder ? 'has-border' : ''
  }

  get focusAreas() {
    const focusAreas = this.$store.getters['profile/getDictionaries']?.focusArea

    return focusAreas || []
  }

  // get filtersComputedOffset() {
  //   return this.selectedFilters?.length ? this.filtersOffset > 0 ? `-${this.filtersOffset}` : 0 : 0;
  // }

  get selectedFilters() {
    const filters: any = []

    Object.keys(this.filters)?.forEach((filterName: any) => {
      const filter: Filter = this.filters[filterName]

      if (filter?.getQueryValue === FilterFunctions.array) {
        if (filter?.value?.length) {
          filters.push({ filterName, label: filter?.label || filterName, value: filter?.value?.join(',') })
        }
      } else {
        if (filter?.value) {
          filters.push({ filterName, label: filter?.label || filterName, value: filter?.value })
        }
      }
    })

    return filters
  }

  removeFilter(filterName: string) {
    this.$nextTick(() => {
      if (filterName && this.filters[filterName] !== undefined) {
        if (this.filters[filterName]?.getQueryValue === FilterFunctions.array) {
          this.$set(this.filters[filterName], 'value', [])
        } else {
          this.$set(this.filters[filterName], 'value', null)
        }
      }
      this.reRender++
      this.$nextTick(() => {
        this.$emit('filtersChange', this.filterQuery)
        // this.handleResize({})
        this.reRender++
      })
    })
  }

  applyFilters() {
    this.$emit('filtersChange', `${this.getFilterQuery()}${this.getOrFilterQuery()}`);
  }

  // eslint-disable-next-line no-unused-vars
  // handleResize ({ width, height }: any) {
  //   this.filtersOffset = (this.$refs?.filtersContainer as HTMLDivElement)?.offsetHeight
  // }

  async mounted() {
    if (this.tableData) {
      await this.loadDataFromQueryParams()
    }
  }
}
