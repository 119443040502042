var render = function () {
  var _vm$insights;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "dashboardModule",
    staticClass: "dashboard-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('GenproxPageHeading', {
    ref: "gnPageHeading",
    attrs: {
      "title": "Insights",
      "description": "Check our last updates and tutorials."
    }
  }), _c('insight-filters-new', {
    ref: "filters",
    attrs: {
      "hasBorder": _vm.hasBorder
    },
    on: {
      "filtersChange": _vm.onFiltersChange,
      "getItems": _vm.getInsights
    }
  }), (_vm$insights = _vm.insights) !== null && _vm$insights !== void 0 && _vm$insights.length ? _c('section', {
    staticClass: "m-post-list"
  }, [_c('div', {
    staticClass: "m-post-list__container"
  }, _vm._l(_vm.insights, function (insight) {
    return _c('div', {
      key: insight.id,
      staticClass: "m-post-list__box"
    }, [_c('post-box', {
      attrs: {
        "details": insight
      }
    })], 1);
  }), 0), _c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.totalCount,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "limit": _vm.perPage,
      "first-number": true,
      "last-number": true,
      "hide-goto-end-buttons": true
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('ChevronLeftIcon')];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('ChevronRightIcon')];
      },
      proxy: true
    }], null, false, 3359769828),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1) : _c('section', {
    staticClass: "m-post-list"
  }, [_c('GenproxDataPlaceholder', {
    attrs: {
      "header": "No insights were found.",
      "description": "Try to change your search filters."
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }