import { render, staticRenderFns } from "./PostBox.vue?vue&type=template&id=0beb914c&scoped=true&"
import script from "./PostBox.vue?vue&type=script&lang=ts&"
export * from "./PostBox.vue?vue&type=script&lang=ts&"
import style0 from "./PostBox.vue?vue&type=style&index=0&id=0beb914c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0beb914c",
  null
  
)

export default component.exports