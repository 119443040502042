var render = function () {
  var _vm$filter, _vm$filter2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-filter"
  }, [_c('div', {
    staticClass: "gn-filter__inner"
  }, [_c('p', {
    staticClass: "gn-filter__label"
  }, [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")((_vm$filter = _vm.filter) === null || _vm$filter === void 0 ? void 0 : _vm$filter.label)) + ":")]), _c('p', {
    staticClass: "gn-filter__value"
  }, [_vm._v(_vm._s((_vm$filter2 = _vm.filter) === null || _vm$filter2 === void 0 ? void 0 : _vm$filter2.value))])]), _c('div', {
    staticClass: "gn-filter__icon",
    on: {
      "click": function click($event) {
        return _vm.$emit('removeFilter');
      }
    }
  }, [_c('XMarkIcon')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }